export const Constants = {
  google_recaptcha_v3_sitekey: "6Ldrn3opAAAAAFxtY8X9HPOwzaPBtw2UHMmSaH6L",

  apiHostUri_dev: "https://localhost:5001/api",
  apiHostUri_prod: "https://api.opvangreview.nl/api",
  apiHostVersion: "v1",
  apiHostKey: "BA9AC272-8039-429C-9C03-21F8B390FEE7",
  apiHostUsername: "opvangreview-pwa-api-user",

  apiRouteAuth: "auth",
  apiRouteLogin: "login",
  apiRouteCompany: "company",
  apiRouteMessage: "message",
  apiRouteReview: "review",
  apiRouteContactform: "contactform",

  maxSearchResults: 50,
};
