import React from "react";
import ContactForm from "../shared/ContactForm";

const Contact: React.FC = () => {
  return (
    <div>
      <div className="row align-items-center g-lg-5 g-sm-5 mb-5">
        <div className="row align-items-center g-lg-5 rounded-bottom">
          <div className="text-center text-lg-start pt-5">
            <h1 className="hero-title">Contact</h1>
          </div>
        </div>

        <div className="herohead-35 hero-contact-bg" />
      </div>

      <div className="row align-items-center g-lg-5 py-5 after-hero-mobile after-hero">
        <div className="col-md-6 col-sm-12 text-center text-lg-start mt-4">
          <div className="row mb-5">
            <p>
              Wij stellen alle feedback en suggesties op prijs. <br />
              Heb je vragen of opmerkingen over onze website of de informatie
              die wij verstrekken, neem dan gerust contact met ons op. <br />
              <br />
              We verwelkomen alle berichten,{" "}
              <strong>
                ook berichten die wijzen op onjuistheden of verouderde
                informatie
              </strong>
              , zodat we ervoor kunnen zorgen dat de informatie op onze site zo
              nauwkeurig en actueel mogelijk is.
            </p>

            <p>
              Je kunt ons bereiken via{" "}
              <a href="mailto:info@opvangreview.nl">info@opvangreview.nl</a> of
              maak gebruik van het contactformulier.
              <br />
              <br />
              We horen graag van je!
            </p>

            <p>Bedankt voor uw bezoek aan onze website.</p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card mb-5">
            <div className="card-body">
              <h4 className="card-title">Ons e-mail adres</h4>
              <p className="card-text">
                <a href="mailto:info@opvangreview.nl">info@opvangreview.nl</a>
              </p>
            </div>
          </div>

          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
