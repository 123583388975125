import React, { useState } from "react";
import { ICompany } from "../../models/ICompany";
import { useNavigate } from "react-router-dom";
import { companyTypeDescription } from "../../models/globals/enum/companyType";
import ReactPaginate from "react-paginate";

interface CompanyListProps {
  companies: ICompany[];
}

const CompanyList: React.FC<CompanyListProps> = ({ companies }) => {
  const navigate = useNavigate();
  const itemsPerPage = 10;

  const openCompanyDetail = (company: ICompany) => {
    navigate(`/reviews/${company.lrkNummer}/${company.naam}`);
  };

  const PaginatedItems = ({ itemsPerPage }: { itemsPerPage: number }) => {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = companies?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(companies?.length / itemsPerPage);

    const handlePageClick = (event: any) => {
      const newOffset = (event.selected * itemsPerPage) % companies?.length;
      setItemOffset(newOffset);
    };

    return (
      <div className="div-companyList">
        {companies === null && (
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Geen instanties gevonden</h5>
              <p className="card-text">
                Met deze filters zijn er geen instanties gevonden. Probeer het
                nog eens met een andere combinatie van filters.
              </p>
            </div>
          </div>
        )}

        <Items currentItems={currentItems} />
        {companies?.length > itemsPerPage && (
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            nextLabel="volgende >"
            previousLabel="< vorige"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    );
  };

  const Items = ({ currentItems }: { currentItems: ICompany[] }) => {
    return (
      <div className="div-companyList" id="searchresults">
        {currentItems &&
          currentItems.map((company: ICompany) => (
            <div
              className="card card-custom mb-3"
              key={company.id}
              onClick={() => {
                openCompanyDetail(company);
              }}
            >
              <div className="card-body">
                <h5 className="card-title">
                  {company.naam}
                  <span className="float-end">
                    <div className="stars_reviewscard">
                      {company.ratingTotal >= 1 ? (
                        <i className="fa fa-star text-warning"></i>
                      ) : (
                        ""
                      )}
                      {company.ratingTotal >= 2 ? (
                        <i className="fa fa-star text-warning"></i>
                      ) : (
                        ""
                      )}
                      {company.ratingTotal >= 3 ? (
                        <i className="fa fa-star text-warning"></i>
                      ) : (
                        ""
                      )}
                      {company.ratingTotal >= 4 ? (
                        <i className="fa fa-star text-warning"></i>
                      ) : (
                        ""
                      )}
                      {company.ratingTotal >= 5 ? (
                        <i className="fa fa-star text-warning"></i>
                      ) : (
                        ""
                      )}
                    </div>
                  </span>
                </h5>
                <div className="card-text card-custom-review-ratingtotal">
                  {company?.naam} is een{" "}
                  {companyTypeDescription[
                    company?.companyType ?? 0
                  ].toLocaleLowerCase()}{" "}
                  in de regio {company?.verantwoordelijkeGemeente} met momenteel
                  plek voor {company?.aantalKindplaatsen} kinderen.
                </div>
                <p className="card-text">
                  <small className="text-muted float-end">
                    {company.reviews?.length} Reviews
                  </small>
                </p>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="div-companyList mb-5">
      <PaginatedItems itemsPerPage={itemsPerPage} />
    </div>
  );
};

export default CompanyList;
