import { IObjectResponse } from "../models/IResponse";
import authService, { AccessToken } from "./authService";
import { ContentType } from "../models/globals/enum/ContentType";
import { ApiConfig } from "../models/globals/Api-config";

export class BaseService {
  public static async get<T>(
    path: string,
    type: ContentType = ContentType.Json
  ): Promise<IObjectResponse<T>> {
    const token: AccessToken = await authService.getToken();
    const init: RequestInit = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        "Content-Type": "application/json",
        "X-Api-Key": ApiConfig.api.key, // Use the imported IConfig interface
      },
      method: "GET",
    };
    return this.fetchRequest<T>(path, type, init);
  }
  public static async post<T>(
    path: string,
    data: any,
    type: ContentType = ContentType.Json,
    noAuth: boolean = false
  ): Promise<IObjectResponse<T>> {
    const token = noAuth ? "" : await authService.getToken();
    const init: RequestInit = {
      headers: {
        Authorization: noAuth
          ? ""
          : `Bearer ${(token as AccessToken).accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      method: "POST",
    };
    return this.fetchRequest<T>(path, type, init);
  }
  public static async delete<T>(
    path: string,
    type: ContentType = ContentType.Json
  ): Promise<IObjectResponse<T>> {
    const token = await authService.getToken();
    const init: RequestInit = {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    };
    return this.fetchRequest<T>(path, type, init);
  }
  public static async put<T>(
    path: string,
    data: any,
    type: ContentType = ContentType.Json
  ): Promise<IObjectResponse<T>> {
    const token = await authService.getToken();
    const init: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      method: "PUT",
    };
    return this.fetchRequest<T>(path, type, init);
  }
  private static async fetchRequest<T>(
    path: string,
    type: ContentType,
    init?: RequestInit
  ): Promise<any> {
    try {
      const response = await fetch(path, init);

      if (response.status === 200) {
        switch (type) {
          case ContentType.Text:
            return response.text().then((r) => {
              return { success: true, payLoad: r };
            });
          case ContentType.Blob:
            return response.blob().then((r) => {
              return { success: true, payLoad: r };
            });
          case ContentType.void:
            return ({} as Promise<T>).then((r) => {
              return { success: true, payLoad: r };
            });
          default:
            return response.json().then((r) => {
              return { success: true, payLoad: r };
            });
        }
      } else if (response.status === 204) {
        return ({} as Promise<T>).then((r) => {
          return { success: true, payLoad: r };
        });
      } else if (response.status === 400 || response.status === 404) {
        return response.text().then((r) => {
          console.error(`API error (${response.status})`, r);
          return {
            success: false,
            errorMessage: r,
            errorCode: response.status,
            payLoad: null,
          };
        });
      } else {
        return response.text().then((r) => {
          console.error(`API error (${response.status})`, r);
          return {
            success: false,
            errorMessage: "Er ging iets mis tijdens de aanvraag.",
            errorCode: response.status,
            payLoad: null,
          };
        });
      }
    } catch (e) {
      console.error("error", e);
      return {
        success: false,
        errorMessage: "Er ging iets mis tijdens de aanvraag.",
        errorCode: 500,
        payLoad: null,
      };
    }
  }
}
