import { useEffect, useState } from "react";
import { ICompany } from "../../models/ICompany";
import { IReview } from "../../models/IReview";
import ReactPaginate from "react-paginate";

interface ReviewsProps {
  paramCompany: ICompany;
}

const Reviews: React.FC<ReviewsProps> = ({ paramCompany }) => {
  // const [company, setCompany] = useState<ICompany>();
  const [reviews, setReviews] = useState<IReview[]>([]);

  useEffect(() => {
    // setCompany(paramCompany);
    setReviews(paramCompany.reviews);
  }, [paramCompany]);

  const PaginatedItems = ({ itemsPerPage }: { itemsPerPage: number }) => {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = reviews.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(reviews.length / itemsPerPage);

    const handlePageClick = (event: any) => {
      const newOffset = (event.selected * itemsPerPage) % reviews.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        {reviews === null ||
          (reviews.length === 0 && (
            <p>
              Er zijn nog geen reviews voor deze locatie. <br />
              Kan jij er een schrijven?
            </p>
          ))}

        <Items currentItems={currentItems} />
        {reviews?.length > itemsPerPage && (
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            nextLabel="volgende >"
            previousLabel="< vorige"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </>
    );
  };

  const Items = ({ currentItems }: { currentItems: IReview[] }) => {
    return (
      <div className="div-reviewList">
        {currentItems &&
          currentItems.map((review: IReview) => (
            <div className="card card-custom card-custom-review">
              <div className="card-body">
                <h4 className="card-title row col-12">
                  <div className="col-6 my-2">
                    {review.userName}: {review.title}
                  </div>
                  <div className="col-6">
                    <span className="card-custom-review-stars-div-mobile card-custom-review-stars-div">
                      <div className="stars_reviewscard">
                        {review.rating >= 1 ? (
                          <i className="fa fa-star text-warning"></i>
                        ) : (
                          ""
                        )}
                        {review.rating >= 2 ? (
                          <i className="fa fa-star text-warning"></i>
                        ) : (
                          ""
                        )}
                        {review.rating >= 3 ? (
                          <i className="fa fa-star text-warning"></i>
                        ) : (
                          ""
                        )}
                        {review.rating >= 4 ? (
                          <i className="fa fa-star text-warning"></i>
                        ) : (
                          ""
                        )}
                        {review.rating >= 5 ? (
                          <i className="fa fa-star text-warning"></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </span>
                  </div>
                </h4>
                <div className="mx-2 mb-2">
                  <p className="card-text">{review.description}</p>
                </div>
                <div className="card-footer">
                  <div className="text-end">
                    <p className="card-text">{review.dateCreated}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="mt-3">
      <h3>Reviews</h3>

      <div className="div-reviews-mobile div-reviews overflow-scroll mb-5">
        <PaginatedItems itemsPerPage={5} />
      </div>
    </div>
  );
};

export default Reviews;
