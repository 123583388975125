export enum FilterOption {
  Naam = 1,
  StraatHuisnummer = 2,
  Postcode = 3,
  Stad = 4,
  LRKNummer = 5,
  KVKNummer = 6,
  Email = 7,
  Telefoonnummer = 8,
  Website = 9,
  // Regio = 10, //TODO Fix region search, disabled for now
}

export type FilterOptionDescription = {
  [key in FilterOption]: string;
};

export const filterOptionDescription: FilterOptionDescription = {
  [FilterOption.Naam]: "Naam",
  [FilterOption.StraatHuisnummer]: "Straat en huisnummer",
  [FilterOption.Postcode]: "Postcode",
  [FilterOption.Stad]: "Stad",
  // [FilterOption.Regio]: "Regio",
  [FilterOption.LRKNummer]: "LRK nummer",
  [FilterOption.KVKNummer]: "KVK nummer",
  [FilterOption.Email]: "Email",
  [FilterOption.Telefoonnummer]: "Telefoonnummer",
  [FilterOption.Website]: "Website",
};

export const filterOptionKeyPairValueList = Object.entries(
  filterOptionDescription
).map(([key, value]) => ({ key, value }));