import { Constants } from "./Constants";

let apiHostValue = Constants.apiHostUri_prod;
let apiVersion = Constants.apiHostVersion;

let apiKey = Constants.apiHostKey;
let apiUsername = Constants.apiHostUsername;

let authRoute = Constants.apiRouteAuth;
let loginRoute = Constants.apiRouteLogin;
let companyRoute = Constants.apiRouteCompany;
let messageRoute = Constants.apiRouteMessage;
let reviewRoute = Constants.apiRouteReview;
let contactformRoute = Constants.apiRouteContactform;

// These values {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used
let envValue = "production";
if (envValue.startsWith("#{")) {
  envValue = "dev";
  apiHostValue = Constants.apiHostUri_dev;
}
console.log("Env: " + envValue);

export const ApiConfig: IApiConfig = {
  env: envValue,
  api: {
    host: apiHostValue,
    version: apiVersion,
    key: apiKey,
    username: apiUsername,
    routing: {
      auth: authRoute,
      login: loginRoute,
      company: companyRoute,
      message: messageRoute,
      review: reviewRoute,
      contactform: contactformRoute,
    },
  },
};

export interface IApiConfig {
  env: string;
  api: {
    host: string;
    version: string;
    key: string;
    username: string;
    routing: {
      auth: string;
      login: string;
      company: string;
      message: string;
      review: string;
      contactform: string;
    };
  };
}
