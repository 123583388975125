import { ApiConfig } from "../models/globals/Api-config";
import { ICompany } from "../models/ICompany";
import { BaseService } from "./baseService";
import { IObjectResponse } from "../models/IResponse";
import { SearchParameters } from "../models/searchParameters";

const baseURL = ApiConfig.api.host;
const companyRoute = ApiConfig.api.routing.company;

export default class CompanyService extends BaseService {
  public static async GetCompanyByLrkId(
    lrkNummer: number
  ): Promise<IObjectResponse<ICompany>> {
    try {
      const result: IObjectResponse<ICompany> = await this.get<ICompany>(
        `${baseURL}/${companyRoute}/lrk/${lrkNummer}`
      );
      console.log(`response: ${result}`);
      return result;
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }
      return error.message;
    }
  }

  public static async GetCompanies(): Promise<IObjectResponse<ICompany[]>> {
    return await this.Search({});
  }

  public static async Search(
    searchParameters: SearchParameters
  ): Promise<IObjectResponse<ICompany[]>> {
    let url = `${baseURL}/${companyRoute}/search?`;
    url = this.ParseSearchParameters(searchParameters, url);

    try {
      let result = await this.get<ICompany[]>(url);
      console.log(`response: ${result}`);
      return result;
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }
      return error.message;
    }
  }

  private static ParseSearchParameters(
    searchParameters: SearchParameters,
    url: string
  ) {
    if (searchParameters.name) {
      url += `name=${searchParameters.name}&`;
    }
    if (searchParameters.street) {
      url += `street=${searchParameters.street}&`;
    }
    if (searchParameters.houseNumber) {
      url += `houseNumber=${searchParameters.houseNumber}&`;
    }
    if (searchParameters.houseNumberAddition) {
      url += `houseNumberAddition=${searchParameters.houseNumberAddition}&`;
    }
    if (searchParameters.postalCode) {
      url += `postalCode=${searchParameters.postalCode}&`;
    }
    if (searchParameters.city) {
      url += `city=${searchParameters.city}&`;
    }
    if (searchParameters.lrkNumber) {
      url += `lrkNumber=${searchParameters.lrkNumber}&`;
    }
    if (searchParameters.kvkNumber) {
      url += `kvkNumber=${searchParameters.kvkNumber}&`;
    }
    if (searchParameters.email) {
      url += `email=${searchParameters.email}&`;
    }
    if (searchParameters.phoneNumber) {
      url += `phoneNumber=${searchParameters.phoneNumber}&`;
    }
    if (searchParameters.website) {
      url += `website=${searchParameters.website}&`;
    }
    if (searchParameters.isGastouder) {
      url += `isGastouder=${searchParameters.isGastouder}&`;
    }
    if (searchParameters.isGastouderBureau) {
      url += `isGastouderBureau=${searchParameters.isGastouderBureau}&`;
    }
    if (searchParameters.isKinderdagverblijf) {
      url += `isKinderdagverblijf=${searchParameters.isKinderdagverblijf}&`;
    }
    if (searchParameters.isBuitenschoolseOpvang) {
      url += `isBuitenschoolseOpvang=${searchParameters.isBuitenschoolseOpvang}&`;
    }
    if (searchParameters.isOuderschapsParticipatie) {
      url += `isOuderschapsParticipatie=${searchParameters.isOuderschapsParticipatie}&`;
    }
    if (searchParameters.isVoorschoolseEducatie) {
      url += `isVoorschoolseEducatie=${searchParameters.isVoorschoolseEducatie}&`;
    }
    if (searchParameters.hasReviews) {
      url += `hasReviews=${searchParameters.hasReviews}&`;
    }
    // Remove the trailing '&' if it exists
    if (url.endsWith("&")) {
      url = url.slice(0, -1);
    }
    return url;
  }
}
