import { ApiConfig } from "../models/globals/Api-config";

const baseURL = ApiConfig.api.host;
const username = ApiConfig.api.username;
const authRoute = ApiConfig.api.routing.auth;
const loginRoute = ApiConfig.api.routing.login;

export default class authService {
  private static accessToken: AccessToken;

  public static async getToken(): Promise<AccessToken> {
    var dateString = new Date();
    if (
      this.accessToken != null &&
      new Date(this.accessToken.expires) > dateString
    ) {
      return this.accessToken;
    }

    try {
      var path = `${baseURL}/${authRoute}/${loginRoute}`;
      var init: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
        }),
      };
      const response = await fetch(path, init);
      console.log({ response });

      if (response.status === 200) {
        const expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() + 1);

        var result = response.json().then((r) => {
          return { success: true, payLoad: r };
        });

        this.accessToken = {
          accessToken: (await result).payLoad.accessToken,
          expires: (await result).payLoad.expires,
        };
      }
    } catch (error: any) {
      return error.message;
    }
    return this.accessToken;
  }
}

export interface AccessToken {
  accessToken: string;
  expires: Date;
}
