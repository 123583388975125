import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ICompany } from "../../models/ICompany";
import companyImage from "../../assets/img/dream.jpg";
import Reviews from "../shared/ReviewList";
import ReviewsInfoCard from "../shared/ReviewsInfoCard";
import ReviewAdd from "../shared/ReviewAdd";
import CompanyInformation from "../shared/CompanyInformation";
import CompanyService from "../../services/companyService";

const CompanyDetail: React.FC = () => {
  const [showloading, setShowloading] = useState<boolean>(false);
  const [company, setCompany] = useState<ICompany>();
  const { lrkNummer } = useParams();

  useEffect(() => {
    const fetchCompanyDetails = async (lrkNummer: string | undefined) => {
      setShowloading(true);

      try {
        if (lrkNummer != null && lrkNummer !== undefined) {
          // parse number from string
          let lrkNummerParsed: number = Number.parseInt(lrkNummer!);
          var result = await CompanyService.GetCompanyByLrkId(lrkNummerParsed);
          if (result != null && result.success) {
            setCompany(result.payLoad);
            document.title = `${result.payLoad.naam} | Reviews`;
          } else {
            alert(
              "Het ophalen van de gegevens niet gelukt. Probeer het later opnieuw."
            );
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setShowloading(false);
      }
    };
    fetchCompanyDetails(lrkNummer);
  }, [lrkNummer]);

  return (
    <div className="div-companydetail">
      <div className="row align-items-center mb-5">

        {/* loading spinner */}
        {showloading && (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Informatie ophalen ...</span>
          </div>
        )}

        <div className="row align-items-center g-lg-5 rounded-bottom">
          <div className="col-lg-6 col-sm-12 text-center text-lg-start">
            <img
              className="img-fluid img-thumbnail"
              src={companyImage}
              alt={company?.naam}
              style={{ width: "50em" }}
            />
          </div>
          <div className="col-lg-6 col-sm-12 text-center text-lg-start">
            <h1 className="hero-title mt-2">{company?.naam}</h1>
            <ReviewsInfoCard paramCompany={company!} />
          </div>
          <div className="herohead hero-contact-bg" />
        </div>

        <div className="after-hero-mobile after-hero" />
        <div className="row custom-row">
          {/* Reviews */}
          <div className="col-lg-6 col-sm-12 text-center text-lg-start">
            {company && <Reviews paramCompany={company as ICompany} />}
          </div>

          {/* Company information */}
          <div className="col-lg-6 col-sm-12 text-center text-lg-start">
            <CompanyInformation paramCompany={company as ICompany} />
          </div>

          <div className="col-lg-12 text-center text-lg-start" id="new-review">
            {/* Add review */}
            <ReviewAdd paramCompany={company!} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetail;
