import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopAfterNavigate = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    /* settimeout make sure this run after components have rendered. This will help fixing bug for some views where scroll to top not working perfectly */
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, [pathname]);
  return null;
};

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="top-to-btm">
      {showTopBtn && (
        <>
          <button
            type="submit"
            className="btn button-30 btn-lg btn-outline-success icon-position"
            onClick={goToTop}
          >
            <i className="fa-solid fa-chevron-up"></i>
          </button>
        </>
      )}
    </div>
  );
};

export default ScrollToTop;
export { ScrollToTopAfterNavigate };
