import React from "react";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const navigation = useNavigate();

  return (
    <>
      <div className="row align-items-center g-lg-5 g-md-5 g-sm-12 mt-3">
        <div className="text-center text-lg-start">
          <h3 className="hero-subtitle">
            Vind de beste kinderopvang voor je kind.
          </h3>
          <h1 className="hero-title">
            Reviews van ouders
            <br />
            voor ouders.
          </h1>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12">
          <div className="text-center">
            <h3 className="hero-subtitle mt-5">
              Zoek op naam, stad, regio of bijvoorbeeld op LRK of KVK
              identificatie nummer
            </h3>
          </div>
          <div className="text-center mt-5">
            <button
              type="submit"
              className="btn button-30 btn-lg btn-outline-success"
              aria-label="Zoeken"
              onClick={() => {
                navigation("/reviews");
              }}
            >
              <i className="fa fa-search button-icon"></i>
              Zoeken
            </button>
          </div>
        </div>
        <div className="herohead herohead-mobile hero-home-bg" />
      </div>

      <div className="row align-items-center g-lg-4 g-md-12 g-sm-12 after-hero-home-xl after-hero-home rounded-bottom">
        <div className="col-lg-4 col-sm-12 text-center pb-5">
          <div className="card d-flex card-custom">
            <div className="card-content pb-2">
              <h5 className="card-header info-box-icon-box text-white">
                <div className="card-icon info-box-icon info-box-icon-pink">
                  <i className="fa fa-heart"></i>
                </div>
                Persoonlijke informatie
              </h5>
              <p className="card-text p-2">
                Reviews van ouders voor ouders. <br />
                Zo kunnen we samen voor meer duidelijkheid in de kinderopvang
                zorgen.
                <br />
                Handig toch?
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 text-center pb-5">
          <div className="card d-flex card-custom">
            <div className="card-content pb-2">
              <h5 className="card-header info-box-icon-box text-white">
                <div className="card-icon info-box-icon info-box-icon-blue">
                  <i className="fa fa-robot"></i>
                </div>
                Geautomatiseerde verzameling
              </h5>
              <p className="card-text p-2">
                Elke 2 weken worden de gegevens van het Landelijke Register
                Kinderopvang opnieuw verwerkt. <br />
                <br />
                Zo hebben we altijd de laatste gegevens. <br />
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 text-center pb-5">
          <div className="card d-flex card-custom">
            <div className="card-content pb-2">
              <h5 className="card-header info-box-icon-box text-white">
                <div className="card-icon info-box-icon info-box-icon-yellow">
                  <i className="fa fa-user"></i>
                </div>
                Een platform voor iedereen
              </h5>
              <p className="card-text p-2">
                Alle geregistreerde instanties van het Landelijke Register
                Kinderopvang, ongeveer 36.000, staan in onze database. <br />{" "}
                <br />
                Toegankelijk voor iedereen.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col text-center">
          <h4 className="mb-5">
            Help ouders met zoeken. <br />
            Laat een review achter.
          </h4>
          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className="btn button-30 btn-lg mx-2 btn-outline-success"
              aria-label="Zoeken"
              onClick={() => {
                navigation("/reviews");
              }}
            >
              <i className="fa fa-search button-icon"></i>
              Zoeken
            </button>

            <button
              type="submit"
              className="btn button-30 btn-lg mx-2 btn-outline-primary"
              aria-label="Over ons"
              onClick={() => {
                navigation("/over-ons");
              }}
            >
              <i className="fa fa-user button-icon"></i>
              Over ons
            </button>
          </div>
        </div>
      </div>

      <div className="row mb-5 mt-5">
        <div className="col">
          <h4 className="text-center">
            Maak een weloverwogen beslissing, <br />
            verzamel eerst meer informatie <br />
            over kinderdagverblijven bij jou in de buurt.
          </h4>
          <p className="text-center">
            Onze beoordelingen zijn geschreven door ouders zoals jij, die uit de
            eerste hand ervaring hebben met de betreffende kinderdagverblijven.
            We streven ernaar een platform te bieden waar ouders hun eerlijke
            mening kunnen delen. Hiermee worden anderen geholpen om beter
            beslissingen te nemen over waar ze hun kleintjes kunnen inschrijven.
          </p>
        </div>
      </div>
    </>
  );
};

export default Home;
