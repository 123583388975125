export enum CompanyType {
  Nog_niet_ingedeeld = 0,
  Kinderdagverblijf = 1,
  Gastouder = 2,
  Tussenschoolse_opvang = 3,
  Gastouder_Bureau = 4,
}

export type CompanyTypeDescription = {
  [key in CompanyType]: string;
};

export const companyTypeDescription: CompanyTypeDescription = {
  [CompanyType.Nog_niet_ingedeeld]: "Nog niet ingedeeld",
  [CompanyType.Kinderdagverblijf]: "Kinderdagverblijf",
  [CompanyType.Gastouder]: "Gastouder",
  [CompanyType.Tussenschoolse_opvang]: "Buitenschoolse opvang",
  [CompanyType.Gastouder_Bureau]: "Gastouder bureau",
};
