import { IObjectResponse } from "../models/IResponse";
import { IReview } from "../models/IReview";
import { BaseService } from "./baseService";
import { ApiConfig } from "../models/globals/Api-config";

const baseURL = ApiConfig.api.host;
const reviewRoute = ApiConfig.api.routing.review;

export default class ReviewService extends BaseService {
  public static async CreateReview(
    review: IReview
  ): Promise<IObjectResponse<IReview>> {
    try {
      const response: any = await this.post<IReview>(
        `${baseURL}/${reviewRoute}`,
        review
      );
      console.log({ response });
      return response;
    } catch (error: any) {
      return error.message;
    }
  }

  public static async UpdateReview(
    review: IReview
  ): Promise<IObjectResponse<IReview>> {
    try {
      const response: any = await this.put<IReview>(
        `${baseURL}/${reviewRoute}`,
        review
      );
      console.log({ response });
      return response;
    } catch (error: any) {
      return error.message;
    }
  }
}
