import React, { Component } from "react";
import "../../assets/css/Footer.css";

export class Footer extends Component {
  render() {
    return (
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <span className="text-muted">
            Opvangreview.nl is een project van{" "}
            <a
              href="https://www.engine-it.eu"
              target="_blank"
              rel="noopener noreferrer"
            >
              Engine-IT
            </a>
            .
          </span>
        </footer>
      </div>
    );
  }
}
