import { useEffect, useState } from "react";
import { ICompany } from "../../models/ICompany";
import { companyTypeDescription } from "../../models/globals/enum/companyType";

interface CompanyInformationProps {
  paramCompany: ICompany;
}

const CompanyInformation: React.FC<CompanyInformationProps> = ({
  paramCompany,
}) => {
  const [company, setCompany] = useState<ICompany>();
  const link_kvk = `https://www.kvk.nl/zoeken/?source=all&q=${company?.kvkNummer}`;
  const link_GoogleMaps = `https://maps.google.com/?q=${company?.opvanglocatieAdres},${company?.opvanglocatieWoonplaats}`;
  const link_callTo_telefoon = `tel:${company?.contactTelefoon}`;
  const link_email = `mailto:${company?.contactEmailadres}`;
  const link_website = `${company?.contactWebsite}`;
  const inschrijfDatumString = company?.lrkInschrijfdatum
    ? new Date(company.lrkInschrijfdatum).toLocaleDateString()
    : "-";

  useEffect(() => {
    setCompany(paramCompany);
  }, [paramCompany]);

  return (
    <div className="div-companyInformation mb-3">
      {/* Row 1 */}
      <div className="mt-3">
        <h3>Algemene informatie</h3>

        <div className="card card-custom p-3">
          <p>
            {company?.naam} is een{" "}
            {companyTypeDescription[
              company?.companyType ?? 0
            ].toLocaleLowerCase()}{" "}
            in de regio {company?.verantwoordelijkeGemeente} met momenteel plek
            voor {company?.aantalKindplaatsen} kinderen.
          </p>
          <div className="table-responsive text-break">
            <table className="table table-striped">
              <thead>
                <th className="w-50"></th>
                <th className="w-50"></th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>LRK nummer:</b>
                  </td>
                  <td>
                    <a href={company?.lrkLink} target="_blank" rel="noreferrer">
                      {company?.lrkNummer}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>LRK inschrijving:</b>
                  </td>
                  <td>{company?.isIngeschreven ? "Actief" : "Niet actief"}</td>
                </tr>
                <tr>
                  <td>
                    <b>KVK nummer:</b>
                  </td>
                  <td>
                    {" "}
                    <a href={link_kvk} target="_blank" rel="noreferrer">
                      {company?.kvkNummer}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>CBS nummer:</b>
                  </td>
                  <td>{company?.cbsCode}</td>
                </tr>
                <tr>
                  <td>
                    <b>Rechtsvorm:</b>
                  </td>
                  <td>{company?.rechtsvorm}</td>
                </tr>
                <tr>
                  <td>
                    <b>Type instantie:</b>
                  </td>
                  <td>{companyTypeDescription[company?.companyType ?? 0]}</td>
                </tr>
                <tr>
                  <td>
                    <b>Inschrijfdatum:</b>
                  </td>
                  <td>{inschrijfDatumString}</td>
                </tr>
                <tr>
                  <td>
                    <b>Ouderparticipatie:</b>
                  </td>
                  <td>
                    {" "}
                    <a
                      href="https://www.rijksoverheid.nl/onderwerpen/kinderopvang/vraag-en-antwoord/regels-ouderparticipatiecreche"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {company?.isOuderschapsParticipatie ? "Ja" : "Nee"}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Voorschoolse educatie:</b>
                  </td>
                  <td>
                    <a
                      href="https://www.rijksoverheid.nl/onderwerpen/voorschoolse-en-vroegschoolse-educatie/voorschoolse-educatie"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {company?.isVoorschoolEducatie ? "Ja" : "Nee"}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Row 2 */}
          <div className="col-12">
            <h3 className="my-4">Adres gegevens</h3>
            <div className="table-responsive text-break">
              <table className="table table-striped">
                <thead>
                  <th className="w-50"></th>
                  <th className="w-50"></th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Adres:</b>
                    </td>
                    <td>
                      <a
                        href={link_GoogleMaps}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {company?.houder_adres}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Postcode:</b>
                    </td>
                    <td>{company?.opvanglocatiePostcode}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Woonplaats:</b>
                    </td>
                    <td>{company?.opvanglocatieWoonplaats}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Verantwoordelijke gemeente:</b>
                    </td>
                    <td>{company?.verantwoordelijkeGemeente}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Row 3 */}
          <div className="col-12">
            <h3 className="my-4">Contact gegevens</h3>
            <div className="table-responsive text-break">
              <table className="table table-striped">
                <thead>
                  <th className="w-50"></th>
                  <th className="w-50"></th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Contactpersoon:</b>
                    </td>
                    <td>{company?.contactPersoon}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Telefoon:</b>
                    </td>
                    <td>
                      <a href={link_callTo_telefoon}>
                        {company?.contactTelefoon}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Email:</b>
                    </td>
                    <td>
                      <a href={link_email}>{company?.contactEmailadres}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Website:</b>
                    </td>
                    <td>
                      <a href={link_website} target="_blank" rel="noreferrer">
                        {company?.contactWebsite.replace(
                          /^(https?:\/\/)?(www\.)?/i,
                          ""
                        )}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
