import { useEffect, useState } from "react";
import { ICompany } from "../../models/ICompany";
import { IReview } from "../../models/IReview";
import ReviewService from "../../services/reviewService";
import { Constants } from "../../models/globals/Constants";

interface ReviewAddProps {
  paramCompany: ICompany;
}

const ReviewAdd: React.FC<ReviewAddProps> = ({ paramCompany }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [company, setCompany] = useState<ICompany>();
  const [rating, setRating] = useState(0);

  const googleRecaptchaKey = Constants.google_recaptcha_v3_sitekey;

  useEffect(() => {
    setCompany(paramCompany);
  }, [paramCompany]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    grecaptcha.ready(function () {
      grecaptcha
        .execute(googleRecaptchaKey, { action: "submit" })
        .then(async function (responseToken) {
          const inputs = e.target.elements;
          let review: IReview = {
            id: 0,
            title: inputs.title.value,
            userEmail: inputs.email.value,
            userName: inputs.name.value,
            description: inputs.message.value,
            rating: rating,
            dateCreated: new Date()
              .toISOString()
              .slice(0, 19)
              .replace("T", " "),
            companyId: company!.id,
            recaptchaToken: responseToken,
          };

          let result = await ReviewService.CreateReview(review);
          if (result.success) {
            setIsSubmitted(true);
          } else {
            setIsError(true);
            setErrorMessage(result.errorMessage);
            if (result.errorMessage) {
              alert(result.errorMessage);
            } else {
              alert(
                "Er is iets misgegaan bij het verzenden van je review. Probeer het later nog eens."
              );
            }
          }
        });
    });

    setIsLoading(false);
  };

  if (isSubmitted) {
    return (
      <div className="row">
        <div className="col-lg-2">
          <i className="fa fa-check-circle fa-4x text-success"></i>
        </div>
        <div className="col-lg-10">
          <div className="text-2xl">Verzonden.</div>
          <div className="text-md">
            Het is gelukt! Bedankt voor het plaatsen van je review.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card card-custom" id="new-review">
      <div className="card-body">
        <h4 className="card-title">Plaats een review</h4>
        <form
          onSubmit={handleSubmit}
          className="g-recaptcha focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400"
        >
          <div className="form-group">
            <label>Beoordeling</label> <br />
            <div className="div-stars">
              <input
                className="star star-5"
                id="star-5"
                type="radio"
                name="star"
                onClick={() => setRating(5)}
              />
              <label
                className="star star-5 fa fa-star"
                htmlFor="star-5"
              ></label>

              <input
                className="star star-4"
                id="star-4"
                type="radio"
                name="star"
                onClick={() => setRating(4)}
              />
              <label
                className="star star-4 fa fa-star"
                htmlFor="star-4"
              ></label>

              <input
                className="star star-3"
                id="star-3"
                type="radio"
                name="star"
                onClick={() => setRating(3)}
              />
              <label
                className="star star-3 fa fa-star"
                htmlFor="star-3"
              ></label>

              <input
                className="star star-2"
                id="star-2"
                type="radio"
                name="star"
                onClick={() => setRating(2)}
              />
              <label
                className="star star-2 fa fa-star"
                htmlFor="star-2"
              ></label>

              <input
                className="star star-1"
                id="star-1"
                type="radio"
                name="star"
                onClick={() => setRating(1)}
              />
              <label
                className="star star-1 fa fa-star"
                htmlFor="star-1"
              ></label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="title">Titel</label>
            <input type="text" className="form-control" id="title" required />
          </div>
          <div className="form-group">
            <label htmlFor="name">Naam</label>
            <input type="text" className="form-control" id="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail adres</label>
            <input type="email" className="form-control" id="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Beschrijving</label>
            <textarea
              className="form-control"
              id="message"
              rows={5}
              required
            ></textarea>
          </div>

          {isError && (
            <div className="row">
              <div className="col-lg-2">
                <i className="fa fa-times fa-4x text-alert"></i>
              </div>
              <div className="col-lg-10">
                <div className="text-2xl">Review nog niet goedgekeurd.</div>
                <div className="text-md">{errorMessage}</div>
              </div>
            </div>
          )}

          <button
            type="submit"
            className="btn button-30 mt-3 btn-outline-success"
            disabled={isSubmitted}
          >
            {!isLoading && <i className="fa fa-envelope button-icon"></i>}
            {isLoading && (
              <i
                className="spinner-border spinner-border-sm button-icon"
                role="status"
                aria-hidden="true"
              />
            )}
            Verzenden
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReviewAdd;
