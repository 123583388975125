export class SearchParameters {
  name?: string;
  street?: string;
  houseNumber?: string;
  houseNumberAddition?: string;
  postalCode?: string;
  city?: string;
  lrkNumber?: string;
  kvkNumber?: string;
  email?: string;
  phoneNumber?: string;
  website?: string;
  // region?: string; //TODO: Fix region search

  isGastouder?: boolean
  isGastouderBureau?: boolean
  isKinderdagverblijf?: boolean
  isBuitenschoolseOpvang?: boolean

  isOuderschapsParticipatie?: boolean
  isVoorschoolseEducatie?: boolean

  hasReviews?: boolean
}
