import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "./components/page/Home";
import Contact from "./components/page/Contact";
import OverOns from "./components/page/Over-ons";
import Reviews from "./components/page/Reviews";
import CompanyDetail from "./components/page/CompanyDetail";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.css";
import "./assets/css/button.css";
import "./assets/css/navbar.css";
import "./assets/css/hero.css";
import "./assets/css/filter-sidebar.css";
import React from "react";
import Root from "./components/page/Root";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<Root />}>
      <Route element={<Home />} />
      <Route path="reviews" element={<Reviews />} />
      <Route path="reviews/:lrkNummer/:naam" element={<CompanyDetail />} />
      <Route path="over-ons" element={<OverOns />} />
      <Route path="contact" element={<Contact />} />
    </Route>
  )
);

const rootElement = document.getElementById("root");
if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
