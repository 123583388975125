import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import logo from "../../assets/img/Logo_notitle_white.png";

const NavMenu: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setIsCollapsed(true);
  }, [location]);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <header>
      <Navbar className="navbar navbar-dark navbar-expand-lg mb-3">
        <NavbarBrand tag={Link} to="/">
          <img
            src={logo}
            className="navbar-logo"
            alt="Opvangreview.nl logo"
            title="Opvangreview.nl"
          />
          <span className="d-none d-md-inline nav-title">Opvangreview.nl</span>
        </NavbarBrand>
        <NavbarToggler
          onClick={toggleNavbar}
          className="navbar-custom-toggler"
        />
        <Collapse
          className="justify-content-end"
          isOpen={!isCollapsed}
          navbar
          expand="false"
        >
          <ul className="navbar-nav flex-grow nav-pills float-end">
            <NavItem>
              <NavLink
                tag={Link}
                className="text-white nav-link-custom"
                to="/"
                eventkey="home"
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-white nav-link-custom"
                to="/reviews"
                eventkey="reviews"
              >
                Reviews
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-white nav-link-custom"
                to="/over-ons"
                eventkey="over-ons"
              >
                Over ons
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-white nav-link-custom"
                to="/contact"
                eventkey="contact"
              >
                Contact
              </NavLink>
            </NavItem>
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
