import React, { useState } from "react";
import MessageService from "../../services/messageService";
import { IContactform } from "../../models/IContactform";

const ContactForm: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    const inputs = e.target.elements;

    let contactForm: IContactform = {
      name: inputs.name.value,
      email: inputs.email.value,
      subject: inputs.subject.value,
      message: inputs.message.value,
      dateCreated: new Date(),
      ipAddress: "", //TODO: Get IP-address from current user
    };

    const data: any = {};
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    let result = await MessageService.SendContactform(contactForm);
    if (result.success) {
      setSubmitted(true);
    } else {
      alert(
        "Er is iets misgegaan bij het verzenden van je bericht. Probeer het later nog eens."
      );
    }
    setIsLoading(false);
  };

  if (submitted) {
    return (
      <div className="row">
        <div className="col-lg-2">
          <i className="fa fa-check-circle fa-4x text-success"></i>
        </div>
        <div className="col-lg-10">
          <div className="text-2xl">Verzonden.</div>
          <div className="text-md">
            Bedankt voor je bericht. We zullen binnenkort contact met je opnemen.
          </div>
        </div>
      </div>
    );
  }

  return (
    //TODO: Fix contact form: add captcha
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Contactformulier</h4>
        <form
          onSubmit={handleSubmit}
          className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400"
        >
          <div className="form-group">
            <label htmlFor="name">Je naam</label>
            <input type="text" className="form-control" id="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Je e-mailadres</label>
            <input type="email" className="form-control" id="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Onderwerp</label>
            <input type="text" className="form-control" id="subject" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Je bericht</label>
            <textarea
              className="form-control"
              id="message"
              rows={5}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="btn button-30 mt-3 btn-outline-success"
          >
            {!isLoading && <i className="fa fa-envelope button-icon"></i>}
            {isLoading && (
              <i
                className="spinner-border spinner-border-sm button-icon"
                role="status"
                aria-hidden="true"
              />
            )}
            Verzenden
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
