import { BaseService } from "./baseService";
import { ApiConfig } from "../models/globals/Api-config";
import { IContactform } from "../models/IContactform";
import { ContentType } from "../models/globals/enum/ContentType";
import { IObjectResponse } from "../models/IResponse";

const baseURL = ApiConfig.api.host;
const messageRoute = ApiConfig.api.routing.message;
const contactformRoute = ApiConfig.api.routing.contactform;

export default class MessageService extends BaseService {
  public static async SendContactform(
    contactForm: IContactform
  ): Promise<IObjectResponse<IContactform>> {
    try {
      const response: IObjectResponse<IContactform> =
        await this.post<IContactform>(
          `${baseURL}/${messageRoute}/${contactformRoute}`,
          contactForm,
          ContentType.Json,
          false
        );
      console.log({ response });
      return response;
    } catch (error: any) {
      return error.message;
    }
  }
}
