import { Container } from "reactstrap";
import { Footer } from "../layout/Footer";
import NavMenu from "../layout/NavMenu";
import ScrollToTop, { ScrollToTopAfterNavigate } from "../layout/ScrollToTop";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Reviews from "./Reviews";
import OverOns from "./Over-ons";
import Contact from "./Contact";
import CompanyDetail from "./CompanyDetail";

//Create a root page component that will be the parent of all other pages
const Root = () => {
  return (
    <>
      <ScrollToTopAfterNavigate />
      <ScrollToTop />
      <Container>
        <NavMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/reviews/:lrkNummer/:naam" element={<CompanyDetail />} />
          <Route path="/over-ons" element={<OverOns />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Container>
      <Footer />
    </>
  );
};
export default Root;
