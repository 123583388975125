import React, { useState, useEffect } from "react";
import FilterSidebar from "../shared/FilterSidebar";
import { ICompany } from "../../models/ICompany";
import CompaniesList from "../shared/CompanyList";
import CompanyService from "../../services/companyService";
import { SearchParameters } from "../../models/searchParameters";
import { Constants } from "../../models/globals/Constants";

const Reviews: React.FC = () => {
  const companySearchListName: string = "companySearchList";
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [displayNotify, setDisplayNotify] = useState<boolean>(false);
  const [notify_header, setNotifyHeader] = useState<string>("");
  const [notify_body, setNotifyBody] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    let companySearchListFromStorage = localStorage.getItem(
      companySearchListName
    );
    if (companySearchListFromStorage !== null) {
      setCompanies(JSON.parse(companySearchListFromStorage));
    }
  }, []);

  const searchCompanies = async (
    searchParams: SearchParameters
  ): Promise<boolean> => {
    try {
      setShowLoading(true);
      // check if params == null then return, also check if at least 1 value has been set
      if (searchParams == null) {
        return false;
      }

      let result = await CompanyService.Search(searchParams);
      if (result.success) {
        var companySearchList = result.payLoad;
        if (companySearchList.length === Constants.maxSearchResults) {
          showNotify(
            "Maximaal aantal resultaten",
            "Alleen de eerste " +
              Constants.maxSearchResults +
              " resultaten worden getoond. Verfijn uw zoekopdracht om meer resultaten te zien."
          );
        }
        setCompanies(companySearchList);

        // save the results in local storage
        localStorage.setItem(
          companySearchListName,
          JSON.stringify(companySearchList)
        );

        const element = document.getElementById("searchresults");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }

        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      setShowLoading(false);
    }
  };

  const clearResults = () => {
    setCompanies([]);
    localStorage.removeItem(companySearchListName);
  };

  const showNotify = (header: string, body: string) => {
    setNotifyHeader(header);
    setNotifyBody(body);
    setDisplayNotify(true);

    setTimeout(() => {
      setDisplayNotify(false);
    }, 5000);
  };

  return (
    <div className="div-companySearch">
      <div className="row align-items-center g-lg-5 g-sm-5 mb-5">
        <div className="row align-items-center g-lg-5 rounded-bottom">
          <div className="text-center text-lg-start pt-5">
            <h1 className="hero-title">Reviews</h1>
          </div>

          <div className="herohead-35 hero-reviews-bg" />
        </div>
      </div>

      <div className="row after-hero-mobile after-hero">
        <div className="col-lg-4 mt-4">
          <FilterSidebar
            fetchData={searchCompanies}
            clearResults={clearResults}
            showNotify={showNotify}
          />

          {/* Alert area */}
          {displayNotify && (
            <div
              className="alert alert-warning fade show mt-5 bottom-left-alert"
              role="alert"
            >
              <strong>{notify_header}</strong> <br />
              {notify_body}
            </div>
          )}
        </div>

        <div className="col-lg-8">
          {showLoading && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Informatie ophalen ...</span>
              </div>
            </div>
          )}

          <CompaniesList companies={companies} />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
